import axios from 'axios'
import { debounce } from 'lodash-es'

const PredictiveSearch = ({
  query
}) => ({
  q: query,
  searchQuery: query,
  hasSearched: false,
  isLoading: false,

  products: [],
  queries: [],

  init () {
    document.addEventListener('keydown', (event) => {
      if (event.key === 'Escape') {
        this.handleClearClick()
      }
    })
  },

  handleQueryInput: debounce(async function () {
    if (this.q === '') {
      this.hasSearched = false
      this.isLoading = false

      this.products = []
      this.queries = []

      return
    }

    if (this.q.length < 3) return

    try {
      const response = await axios.get(`/search/suggest.json?q=${this.q}&resources[type]=product,query&resources[limit]=4&resources[limit_scope]=each`)

      this.hasSearched = true

      const { products, queries } = response.data.resources.results

      this.searchQuery = this.q
      this.products = products
      this.queries = queries
    } catch (error) {
      throw new Error('Error in `handleSearchInput`')
    } finally {
      this.isLoading = false
    }
  }, 300),

  handleClearClick () {
    this.q = ''
    this.searchQuery = ''

    this.hasSearched = false
    this.isLoading = false

    this.products = []
    this.queries = []
  }
})

export default PredictiveSearch
