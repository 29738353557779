const MainBlog = () => ({
  isPagination: true,
  isDisabled: false,
  loadNextPage (url) {
    this.isDisabled = true
    fetch(url).then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok')
      }
      return response.text()
    }).then(data => {
      const parser = new DOMParser()
      const doc = parser.parseFromString(data, 'text/html')
      const loadMoreContainer = doc.querySelector('.load-more')?.innerHTML

      const thumbnails = Array.from(doc.querySelectorAll('.blog-card'))
      const container = document.querySelector('.blog-cards__container')
      thumbnails.forEach(thumbnail => {
        container.appendChild(thumbnail.cloneNode(true))
      })

      const loadMore = document.querySelector('.load-more')
      if (loadMoreContainer?.length) {
        loadMore.innerHTML = loadMoreContainer
      } else {
        this.isPagination = false
      }

      const itemCount = loadMore.querySelector('.item-count')
      itemCount.textContent = document.querySelectorAll('.blog-card').length.toString()

      this.isDisabled = false
    }).catch(error => {
      console.error('Error:', error)
    })
  },
  loadMore (url: number) {
    this.loadNextPage(url)
  }
})

export default MainBlog
