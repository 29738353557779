import { getStorefrontAccessToken } from '@scripts/utils/helpers'

const ProductSearchWidget = () => ({
  type: '',
  key: '',
  shop: window.location.origin,
  options (option) {
    return `
      <li>
        <button
          type="button"
          data-id="${option.id}"
          data-handle="${option.handle}"
          x-on:click="selectedOption = '${option.value}'; open = !open; isOptionSelected = true; fetchProducts('${option.value}')"
          class="inline-flex w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-400 dark:hover:bg-gray-600 dark:hover:text-white text-left"
        >
          <div class="inline-flex items-center">${option.value}</div>
        </button>
      </li>
    `
  },
  products (data) {
    const option = data.node
    return `
      <li>
        <button
          type="button"
          x-on:click="selectedOption = '${option.title}'; open = !open; isOptionSelected = true; changeProductRedirect('${option.handle}')"
          class="inline-flex w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-400 dark:hover:bg-gray-600 dark:hover:text-white text-left"
        >
          <div class="inline-flex items-center">${option.title}</div>
        </button>
      </li>
    `
  },
  buildHTML (edges) {
    const dataReconstruct = edges.map((edge) => {
      const node = edge.node
      return {
        id: node.id,
        handle: node.handle,
        value: node.fields[0].value
      }
    })

    let optionsHTML = ''
    dataReconstruct.forEach(option => {
      optionsHTML += this.options(option)
    })

    // Get the target element
    const optionsDropdown = document.querySelector('#dropdown-option ul')
    // Append HTML content to the target element
    optionsDropdown.innerHTML += optionsHTML
  },

  request () {
    const getMetaData = `
        query getMetafields {
          metaobjects(first: 250, sortKey: "date", type: "${this.type}") {
            edges {
              cursor
              node {
                id
                handle
                fields {
                  value
                  key
                  references(first: 250) {
                    edges {
                      node {
                        ... on Product {
                          id
                          title
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      `
    fetch(`${this.shop}/api/graphql`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/graphql',
        'X-Shopify-Storefront-Access-Token': getStorefrontAccessToken()
      },
      body: getMetaData
    })
      .then((response) => response.json())
      .then((data) => {
        const edges = data.data.metaobjects.edges

        console.log('edges', edges)
        console.log('type2', this.type)

        if (edges) {
          this.buildHTML(edges)
        }
      })
      .catch((error) => {
        console.error('Error:', error)
      })
  },
  fetchProducts (value: string) {
    const getProductMetafield = `
      query getProducts {
        products(first: 250) {
          edges {
            node {
              handle
              title
              metafield(key: "${this.key}", namespace: "custom") {
                key
                references(first: 50) {
                  nodes {
                    ... on Metaobject {
                      id
                      field(key: "kind") {
                        value
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    `
    fetch(`${this.shop}/api/graphql`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/graphql',
        'X-Shopify-Storefront-Access-Token': getStorefrontAccessToken()
      },
      body: getProductMetafield
    })
      .then((response) => response.json())
      .then((data) => {
        const filteredEdges = data.data.products.edges.filter(item => {
          return item.node.metafield && item.node.metafield.references && item.node.metafield.references.nodes.some(node => node.field.value === value)
        })

        // Get the target element
        const optionsDropdown = document.querySelector('#dropdown-products ul')

        // Append HTML content to the target element
        optionsDropdown.innerHTML = ''
        filteredEdges.forEach(item => {
          optionsDropdown.innerHTML += this.products(item)
        })
      })
      .catch((error) => {
        console.error('Error:', error)
      })
  },
  changeProductRedirect (url: string) {
    document.getElementById('product-redirect').href = `${this.shop}/products/${url}`
  },
  initialize (type: string, key: string) {
    this.type = type
    this.key = key
    this.request()
  }
})

export default ProductSearchWidget
