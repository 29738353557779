const HeaderMain = () => ({
  isMobileMenuOpen: false,

  activeParentMenu: null,
  activeSubmenu: null,
  lastClickedLink: null,

  toggleMobileMenu () {
    this.isMobileMenuOpen = !this.isMobileMenuOpen

    if (this.isMobileMenuOpen === false) {
      this.activeSubmenu = null
      this.activeParentMenu = null
    }
  },

  setActiveParentMenu (e, linkHandle = null) {
    if (window.matchMedia('(min-width: 1024px)').matches === false && e.type === 'mouseover') return

    if (
      window.matchMedia('(min-width: 1024px)').matches === false &&
      e.type === 'click'
    ) {
      e.preventDefault()
    }

    if (this.activeParentMenu === linkHandle) {
      this.activeParentMenu = null
      return
    }

    this.activeParentMenu =
      typeof linkHandle === 'string' || typeof linkHandle === 'undefined'
        ? linkHandle
        : null
  },

  isDesktop () {
    return window.matchMedia('(min-width: 1024px)').matches // Adjust the threshold as needed
  },

  setActiveSubMenu (e, linkHandle = null) {
    if (!this.isDesktop()) {
      e.preventDefault()
    }

    if (this.activeSubmenu === linkHandle) {
      this.activeSubmenu = null
      return
    }

    this.activeSubmenu =
      typeof linkHandle === 'string' || typeof linkHandle === 'undefined'
        ? linkHandle
        : null
  }

})

export default HeaderMain
