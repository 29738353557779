const QuantityField = ({ max, value, min = 0 }) => ({
  value: Number(value),
  isMaxReached: max === 0 || Number(value) === max,
  isMinReached: max === 0 || Number(value) === min,

  clampValue (inputValue: number) {
    if (inputValue > max) return max
    if (inputValue < min || inputValue < 0) return min
    return inputValue
  },

  updateMinMaxFlags () {
    this.isMaxReached = this.value === max
    this.isMinReached = this.value === min
  },

  handleInputChange () {
    // Accept only numbers
    if (!/^\d*$/.test(this.value)) {
      this.value = this.value.replace(/\D/g, '')
      return
    }

    this.value = this.clampValue(Number(this.value))
    this.updateMinMaxFlags()

    this.$dispatch('quantity-change', { value: this.value })
  },

  handleButtonClick (direction: string) {
    if (direction === 'minus') {
      this.value = this.clampValue(this.value - 1)
    } else if (direction === 'plus') {
      this.value = this.clampValue(this.value + 1)
    }

    this.updateMinMaxFlags()
    this.$dispatch('quantity-change', { value: this.value })
  }
})

export default QuantityField
