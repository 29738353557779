import { getStorefrontAccessToken } from '@scripts/utils/helpers'

const EventsGrid = () => ({
  isPagination: true,
  hasNextPage: '',
  cursor: '',
  type: '',
  numCard: 0,
  itemCount: 6,
  isDisabled: false,
  convertDate (cardDate) {
    const dateString = cardDate
    const date = new Date(dateString)

    const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']

    const dayNames = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']

    const day = dayNames[date.getDay()]
    const dateOfMonth = date.getDate()
    const month = monthNames[date.getMonth()]
    const year = date.getFullYear()

    // Format the date
    const formattedDate = `${day}, ${dateOfMonth} ${month} ${year}`

    return formattedDate // Output: Sunday, 3 Nov 2023
  },
  cardEvent (card) {
    return `
      <div class="mb-10 event-grid__past-list">
        <div class="bg-white overflow-hidden card-event">
          <img src="${card.image_url}" class="card-event__image"/>
          <div class="text-center px-5">
            <h3 class="font-medium card-event__title">${card.title}</h3>
            <p class="mb-4 card-event__sub_title">${card.sub_title}</p>
            <p class="card-event__date">${this.convertDate(card.date)}</p>
          </div>
          <a
            href="${card.cta_url}"
            class="mt-4 text-white font-bold button button--primary card-event__button is-disabled">
              Past Event
            </a>
        </div>
      </div>
    `
  },
  buildHTML (edges) {
    const today = new Date()
    const formattedDate = today.toISOString().split('T')[0]

    const dataReconstruct = edges.map((edge) => {
      const node = edge.node
      return {
        id: node.id,
        handle: node.handle,
        cta_label: node.fields.find(field => field.key === 'cta_label').value,
        cta_url: node.fields.find(field => field.key === 'cta_url').value,
        date: node.fields.find(field => field.key === 'date').value,
        details: node.fields.find(field => field.key === 'details')?.value,
        image_url: node.fields.find(field => field.key === 'image').reference.image.url,
        sub_title: node.fields.find(field => field.key === 'sub_title').value,
        title: node.fields.find(field => field.key === 'title').value
      }
    })

    // Remove objects where date is in the past
    const currentDateReconstruct = dataReconstruct.filter(event => event.date < formattedDate)

    const sortedObj = currentDateReconstruct.sort((a, b) => new Date(b.date) - new Date(a.date))
    if (sortedObj.length && this.numCard === 7) {
      sortedObj.shift() // Remove the first element of the array
    }

    let cardsHTML = ''

    sortedObj.forEach(event => {
      cardsHTML += this.cardEvent(event)
    })
    // Get the target element
    const eventsGridPastLists = document.querySelector('.events-grid__past-lists')

    // Append HTML content to the target element
    eventsGridPastLists.innerHTML += cardsHTML
  },
  request () {
    const shop = window.location.origin
    const nextPage = this.cursor ? `, after: "${this.cursor}"` : ''

    // Check if any element with the class 'events-grid__upcoming' exists
    const upcomingEventsElement = document.querySelector('.events-grid__upcoming')
    if (upcomingEventsElement && this.numCard === 0) {
      this.numCard = 7
    } else {
      this.numCard = 6
    }

    const getMetaData = `
      query getMetafields {
        metaobjects(first: ${this.numCard}, sortKey: "date", type: "${this.type}" ${nextPage}) {
          edges {
            cursor
            node {
              id
              handle
              fields {
                reference {
                  ... on MediaImage {
                    image {
                      url
                    }
                  }
                }
                value
                key
              }
            }
          }
          pageInfo {
            hasPreviousPage
            hasNextPage
            startCursor
            endCursor
          }
        }
      }
    `
    this.isDisabled = true
    fetch(`${shop}/api/graphql`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/graphql',
        'X-Shopify-Storefront-Access-Token': getStorefrontAccessToken()
      },
      body: getMetaData
    })
      .then((response) => response.json())
      .then((data) => {
        const edges = data.data.metaobjects.edges

        if (edges) {
          this.buildHTML(edges)
          const hasNextPage = data.data.metaobjects.pageInfo.hasNextPage

          if (hasNextPage) {
            this.hasNextPage = hasNextPage
            this.cursor = data.data.metaobjects.pageInfo.endCursor
          } else {
            this.hasNextPage = hasNextPage
            this.cursor = ''
            this.isPagination = !this.isPagination
          }
        }
        this.itemCount = document.querySelectorAll('.event-grid__past-list').length
        this.isDisabled = false
      })
      .catch((error) => {
        console.error('Error:', error)
      })
  },
  loadMore (type: string) {
    this.type = type
    this.request()
  }
})

export default EventsGrid
