const ReadMore = (
  { maxHeight }
) => ({
  isCollapsed: true,
  contentHeight: null,

  async init () {
    await this.$nextTick()

    if (this.$refs.content) {
      this.contentHeight = this.$refs.content.clientHeight

      this.$refs.content.style.maxHeight = maxHeight
    }
  },

  toggleCollapsed () {
    this.isCollapsed = !this.isCollapsed

    if (this.isCollapsed) {
      this.$refs.content.style.maxHeight = maxHeight
    } else {
      this.$refs.content.style.maxHeight = this.contentHeight + 'px'

      console.log(this.$refs.content.closest('.accordion__content'))

      if (this.$refs.content.closest('.accordion__content')) {
        this.$refs.content.closest('.accordion__content').style.maxHeight = this.contentHeight + 10 + 'px'
      }
    }
  }
})

export default ReadMore
