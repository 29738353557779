import { storefrontInstance } from '../utils/api'

const IngredientsIndex = () => ({
  ingredients: [],
  endCursor: null,
  hasNextPage: true,

  isLoading: true,

  init () {
    this.fetchIngredients()
  },

  async fetchIngredients () {
    if (!this.hasNextPage) return

    const query = `
      {
        metaobjects(
          type: "ingredients", 
          first: 250, 
          sortKey: "display_name" 
          ${this.endCursor ? `, after: "${this.endCursor}"` : ''}
        ) {
          pageInfo {
            hasNextPage
            endCursor
          }
          edges {
            node {
              id
              field(key: "name") {
                value
              }
            }
          }
        }
      }
    `

    try {
      const res = await storefrontInstance.post('/graphql.json', query)

      if (res.status !== 200) {
        throw new Error('Error in fetching ingredients')
      }

      const { data } = res.data
      const newIngredients = data.metaobjects.edges.map(edge => {
        return {
          id: encodeURIComponent(edge.node.id),
          value: edge.node.field.value
        }
      })

      this.ingredients = [...this.ingredients, ...newIngredients]
      this.hasNextPage = data.metaobjects.pageInfo.hasNextPage
      this.endCursor = data.metaobjects.pageInfo.endCursor

      if (this.hasNextPage) {
        await this.fetchIngredients() // Recursively fetch next page
      } else {
        this.sortIngredients()
      }
    } catch (error) {
      console.error('Error in fetching ingredients:', error)
      throw new Error('Error in fetching ingredients')
    }
  },

  sortIngredients () {
    const groupedIngredients = {}

    // Initialize groupedIngredients with keys from 'A' to 'Z'
    for (let char = 65; char <= 90; char++) {
      groupedIngredients[String.fromCharCode(char)] = []
    }
    // Initialize a special key for non-alphabetic starting characters
    groupedIngredients['#'] = []

    // Populate the groupedIngredients object
    for (const ingredient of this.ingredients) {
      const firstChar = ingredient.value[0].toUpperCase() // Get the first character of the value and convert it to uppercase
      if (firstChar.match(/[A-Z]/)) {
        groupedIngredients[firstChar].push(ingredient)
      } else {
        // Place non-alphabetic ingredients into the special category
        groupedIngredients['#'].push(ingredient)
      }
    }

    // Sort each array of ingredients alphabetically by their value
    for (const key in groupedIngredients) {
      groupedIngredients[key].sort((a, b) => a.value.localeCompare(b.value))
    }

    // Replace the current ingredients array with the newly grouped and sorted object
    this.ingredients = groupedIngredients
    this.isLoading = false
  }

})

export default IngredientsIndex
