import axios from 'axios'
import {
  toUrlParams,
  toFilteredUrlParams,
  updateDOMContent,
  updateURL,
  scrollToSection
} from '@scripts/utils/helpers'

const ELEMENTS_TO_UPDATE_CONTENT = [
  '[data-facets-products-grid]',
  '[data-facets-banner]',
  '[data-products-count-desktop]',
  '[data-products-count-mobile]',
  '[data-facets-pagination]',
  '[data-facets-filters]',
  '[data-facets-sort]'
]

const WINDOW_PATHNAME = window.location.pathname

const FacetsMain = ({
  sectionId,
  activeSort,
  currentPage,
  isSearch = false,
  q = null,
  isCustomFilter = false
}) => ({
  activeSort,
  sectionId,
  filterSortParams: {},
  filterSearchResults: {
    filter: null,
    results: []
  },

  newPage: currentPage,

  previousProductElements: [],

  isFilterDrawerOpen: false,
  isSortOpen: false,
  isLoading: false,
  isPreviousLoading: true,

  init () {
    this.filterSortParams = this.getDefaultParams()

    this.setPreviousPages()
  },

  /**
   * Sets the `previousPages` property to an array of numbers counting down from the current page number minus one
   * down to 1, based on the `page` query parameter in the current URL.
   *
   * This function assumes that the page number in the URL is a valid integer greater than 1.
   *
   * If the page number is 1 or less, or not present, `previousPages` is not set.
   *
   * This implementation uses `Array.from()` to efficiently generate the array
  */
  setPreviousPages (): void {
    const searchParams = new URLSearchParams(window.location.search)
    const urlPage = searchParams.get('page')

    // Convert the page number to an integer
    const currentPage = parseInt(urlPage, 10)

    // If currentPage is NaN or less than or equal to 1, return immediately.
    if (isNaN(currentPage) || currentPage <= 1) {
      this.isPreviousLoading = false
      return
    }

    // Use Array.from to generate the array of previous pages

    this.previousPages = Array.from({ length: currentPage - 1 }, (_, index) => currentPage - 1 - index)

    this.fetchPreviousPages()
  },

  getRequestParams () {
    return {
      ...this.filterSortParams,
      page: this.newPage,
      section_id: this.sectionId
    }
  },

  getDefaultParams () {
    return {
      page: this.newPage,
      section_id: this.sectionId,
      sort_by: this.activeSort,
      ...(isSearch &&
        q && {
        q,
        "options['prefix']": 'last'
      })
    }
  },

  getPageParam (url: string) {
    const pageRegex = /[?&]page=(\d+)/
    const match = url.match(pageRegex)

    if (match && match[1]) {
      return match[1]
    } else {
      return null
    }
  },

  handleSortChange (sortValue) {
    this.activeSort = sortValue

    this.newPage = 1

    this.filterSortParams = {
      ...this.filterSortParams,
      sort_by: this.activeSort
    }

    this.isSortOpen = false

    scrollToSection('FacetsProductsGrid')

    this.handleFormSubmit('', false)
  },

  handleFilterToggleClick () {
    this.isFilterDrawerOpen = !this.isFilterDrawerOpen
  },

  handleSortToggleClick () {
    this.isSortOpen = !this.isSortOpen
  },

  handleFilterChange (event) {
    const formElement = this.$refs.filterForm

    const formData = new FormData(formElement)

    const dataObject = {}

    for (const [key, value] of formData.entries()) {
      if (dataObject[key] && Array.isArray(dataObject[key])) {
        dataObject[key].push(value)
      } else if (dataObject[key]) {
        dataObject[key] = [dataObject[key], value]
      } else {
        dataObject[key] = value
      }
    }

    this.newPage = 1

    this.filterSortParams = {
      ...dataObject,
      ...this.getDefaultParams()
    }

    this.clearFilterSearch()

    scrollToSection('FacetsProductsGrid')

    this.handleFormSubmit('', event?.detail?.shouldUpdateFilters)
  },

  handleClearClick () {
    this.newPage = 1
    this.filterSortParams = this.getDefaultParams()

    if (isCustomFilter === true) {
      window.dispatchEvent(new CustomEvent('clear-click'))
    }

    scrollToSection('FacetsProductsGrid')

    this.handleFormSubmit('', isCustomFilter === false)
  },

  async handleFormSubmit (loadMoreUrl = '', shouldUpdateFilters = true) {
    const serializedParams = toUrlParams(this.getRequestParams())
    const fullUrl = loadMoreUrl || `${WINDOW_PATHNAME}?${serializedParams}`

    try {
      this.isLoading = true

      const response = await axios.get(fullUrl)

      let elementsToUpdateContent = ELEMENTS_TO_UPDATE_CONTENT

      if (loadMoreUrl) {
        // Append the new products to the end of `[data-facets-products-grid]`
        const [productsGrid, ...otherElementsToUpdate] = ELEMENTS_TO_UPDATE_CONTENT

        const productsGridEl = document.querySelector(productsGrid)

        const parser = new DOMParser()
        const doc = parser.parseFromString(response.data, 'text/html')

        const newProductsWrapper = doc.querySelector(productsGrid)
        const newProductsChildren = newProductsWrapper.children

        if (newProductsChildren) {
          [...Array.from(newProductsChildren)].forEach(child => productsGridEl.appendChild(child))
        }

        elementsToUpdateContent = otherElementsToUpdate
      }

      // Remove '[data-facets-filters]' if shouldUpdateFilters is false
      if (shouldUpdateFilters !== true) {
        elementsToUpdateContent = elementsToUpdateContent.filter(el => el !== '[data-facets-filters]')
      }

      elementsToUpdateContent.forEach((el) =>
        updateDOMContent(el, response.data)
      )

      updateURL(
        loadMoreUrl || `${WINDOW_PATHNAME}?${toFilteredUrlParams(this.getRequestParams())}`
      )
    } catch (error) {
      throw new Error('Error in `handleFormSubmit`')
    } finally {
      this.isLoading = false
    }
  },

  async fetchPreviousPages () {
    if (!this.previousPages.length) return

    const currentPageParams = this.$refs.loadMore.getAttribute('data-next-page-url')

    let urls = []

    // Function to remove the 'page' parameter and return the base URL and other parameters
    const removedPageParam = (url: string) => {
      const [baseUrl, queryString] = url.split('?')
      const params = queryString.split('&').filter(param => !param.startsWith('page='))
      return [baseUrl, params.join('&')]
    }

    const [baseUrl, otherParams] = removedPageParam(currentPageParams)
    urls = this.previousPages.map(page => {
      // Reconstruct the URL with the new page number and other existing parameters
      return `${baseUrl}?page=${page}` + (otherParams ? `&${otherParams}` : '')
    })

    try {
      const responses = await Promise.all(urls.map(url => axios.get(url)))
      const parser = new DOMParser()

      responses.forEach(response => {
        const doc = parser.parseFromString(response.data, 'text/html')
        const fetchedProductsGrid = doc.querySelector('[data-facets-products-grid]')
        const children = Array.from(fetchedProductsGrid.children)

        const existingProductsGrid = document.querySelector('[data-facets-products-grid]')

        // Prepend each child to the existing productsGrid directly
        children.reverse().forEach(child => {
          existingProductsGrid.insertBefore(child, existingProductsGrid.firstChild)
        })
      })
    } catch (error) {
      console.error('Error in `fetchPreviousPages`: ', error)
      throw new Error('Failed to fetch previous pages')
    } finally {
      this.isPreviousLoading = false
    }
  },

  handleLoadMoreClick (url: string) {
    if (!url) return

    const nextPage = this.getPageParam(url)

    if (!nextPage) return

    this.newPage = nextPage

    this.handleFormSubmit(url)
  },

  handleSearchResultClear (paramName) {
    const filterInput: HTMLInputElement = document.querySelector(`[data-filter-input="${paramName}"]`)

    console.log(filterInput, 'f')
    filterInput.value = ''

    this.filterSearchResults = {
      filter: null,
      results: []
    }
  },

  handleFilterSearchInput (e, paramName) {
    const value = e.target.value
    const filtersListElement = e.target.closest('fieldset').querySelector('[data-filter-options-list]')

    if (value === '') {
      this.clearFilterSearch()

      return
    }

    if (this.filterSearchResults.filter !== paramName) {
      this.filterSearchResults.filter = paramName
    }

    if (filtersListElement) {
      const listItems = filtersListElement.querySelectorAll('[data-filter-option]')

      if (listItems.length) {
        const normalizedValue = value.replace(/[\W_]+/g, '').toLowerCase()

        const optionResults = Array.from(listItems).filter((item: HTMLElement) => {
          const filterAttribute = item.getAttribute('data-filter-option')

          const normalizedFilterAttibute = filterAttribute.replace(/[\W_]+/g, '').toLowerCase()

          return normalizedFilterAttibute.includes(normalizedValue)
        })

        this.filterSearchResults.results = Array.from(optionResults).map((node: HTMLLIElement) => node.innerHTML)
      }
    }
  },

  clearFilterSearch () {
    this.filterSearchResults = {
      filter: null,
      results: []
    }
  }

})

export default FacetsMain
