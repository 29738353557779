import { debounce, fromPairs } from 'lodash-es'

const CartItem = ({ item }) => ({
  isLoading: Alpine.store('cart').isUpdating,

  quantity: item.quantity,
  itemProperties: fromPairs(item.properties),

  handleQuantityChange: debounce(async function ($event) {
    if (!$event.detail.value) return

    this.quantity = $event.detail.value

    this.isLoading = true

    await Alpine.store('cart').updateCart({
      key: item.key,
      quantity: this.quantity,
      showFetchCartLoading: false
    })

    this.isLoading = false
  }, 250),

  async handleItemRemove () {
    this.isLoading = true

    await Alpine.store('cart').updateCart({
      key: item.key,
      quantity: 0,
      showFetchCartLoading: false
    })

    this.isLoading = false
  },

  async handleGiftWrapChange () {
    this.isLoading = true

    await Alpine.store('cart').updateCart({
      key: item.key,
      quantity: 0,
      shouldFetchCart: false,
      showFetchCartLoading: false
    })

    await Alpine.store('cart').addToCart({

      quantity: item.quantity,
      properties: this.itemProperties,
      shouldFetchCart: true,
      showFetchCartLoading: false,
      showMiniCartOnSuccess: false
    })

    this.isLoading = false
  }

})

export default CartItem
