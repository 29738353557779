import { storefrontInstance } from '@scripts/utils/api'

const FacetsFilterMetaobject = () => ({
  filters: [],
  isLoading: true,
  filterSearchMetaobjectResults: {
    filter: null,
    results: []
  },

  init () {
    this.setFilters()

    window.addEventListener('clear-click', this.clearFilters.bind(this))
  },

  async setFilters () {
    const filtersScript = this.$refs.metaobjectFilters.textContent
    this.filters = JSON.parse(filtersScript)

    for (const filter of this.filters) {
      let endCursor = null
      let hasNextPage = true

      while (hasNextPage) {
        const result = await this.fetchMetaObjects(filter.type, filter.field, endCursor)
        if (result) {
          filter.options.push(...result.nodes.map(node => ({
            id: node.id,
            handle: node.handle,
            name: node.field.value,
            isActive: this.isOptionActive(filter.key, node.id)
          })))
          endCursor = result.pageInfo.endCursor
          hasNextPage = result.pageInfo.hasNextPage
        } else {
          hasNextPage = false
        }
      }

      filter.options.sort((a, b) => a.name.localeCompare(b.name))
    }

    this.isLoading = false
  },

  isOptionActive (filterKey, optionId) {
    const urlParams = new URLSearchParams(window.location.search)

    const paramValues = urlParams.getAll(`filter.p.m.${filterKey}`)
    return paramValues.includes(optionId)
  },

  async fetchMetaObjects (type, field, endCursor = null) {
    const query = `
    {
      metaobjects(type: "${type}", first: 250${endCursor ? `, after: "${endCursor}"` : ''}) {
        pageInfo {
          endCursor
          hasNextPage
        }
        nodes {
          id
          handle
          field(key: "${field}") {
            key
            type
            value
          }
        }
      }
    }`

    try {
      const res = await storefrontInstance.post('/graphql.json', query)

      const data = res.data.data.metaobjects

      return {
        nodes: data.nodes,
        pageInfo: data.pageInfo
      }
    } catch (error) {
      console.error('Error fetching data:', error)
      return null
    }
  },

  handleFilterChange (e) {
    const { name, value, checked } = e.target
    const nameParts = name.split('.')
    const key = nameParts[3] // 'filter.p.m.key' => key is at index 3

    const filter = this.filters.find(f => f.key === key)

    if (filter) {
      const option = filter.options.find(opt => opt.id === value)
      if (option) {
        option.active = checked
      }
    }

    this.$dispatch('metaobject-filter-change', {
      shouldUpdateFilters: false
    })
  },

  handleSearchResultClear (paramName) {
    const filterInput: HTMLInputElement = document.querySelector(`[data-filter-input="${paramName}"]`)

    filterInput.value = ''

    this.filterSearchMetaobjectResults = {
      filter: null,
      results: []
    }
  },

  handleFilterSearchMetaobjectInput (e, paramName) {
    const searchTerm = e.target.value.replace(/[\W_]+/g, '').toLowerCase()
    if (!searchTerm) {
      this.clearFilterSearch()
      return
    }

    const filter = this.filters.find(f => `filter.p.m.${f.key}` === paramName)
    if (!filter) {
      return
    }

    const results = filter.options.filter(option =>
      option.name.replace(/[\W_]+/g, '').toLowerCase().includes(searchTerm)
    )

    this.filterSearchMetaobjectResults = {
      filter: paramName,
      results: results.map(result => ({
        ...result,
        isActive: result.isActive
      }))
    }
  },

  handleSearchResultFilterChange (e) {
    const { name, value, checked } = e.target
    const nameParts = name.split('.')
    const key = nameParts[3] // 'filter.p.m.key' => key is at index 3

    const filter = this.filters.find(filter => filter.key === key)

    if (filter) {
      const option = filter.options.find(option => option.id === value)
      if (option) {
        option.isActive = true
      }
    }

    // Update the search results
    this.filterSearchMetaobjectResults.results = this.filterSearchMetaobjectResults.results.map(result =>
      result.id === value ? { ...result, isActive: checked } : result
    )

    // Update the main filters array
    const updatedFilters = this.filters.map(filter => {
      if (filter.key === `${nameParts[3]}.${nameParts[4]}`) {
        return {
          ...filter,
          options: filter.options.map(option =>
            option.id === value ? { ...option, isActive: checked } : option
          )
        }
      }
      return filter
    })

    this.filters = updatedFilters

    this.$dispatch('metaobject-filter-change', {
      shouldUpdateFilters: false
    })
  },

  clearFilters () {
    this.filters = this.filters.map(filter => ({
      ...filter,
      options: filter.options.map(option => ({
        ...option,
        isActive: false
      }))
    }))
  },

  clearFilterSearch () {
    this.filterSearchMetaobjectResults = {
      filter: null,
      results: []
    }
  }
})

export default FacetsFilterMetaobject
