import { AddressForm } from '@shopify/theme-addresses'

const CustomerAddresses = () => ({
  addressToggles: [],
  isNewAddressFormShown: false,

  init () {
    AddressForm(this.$refs.newAddress, 'en')

    if (this.$refs.addressCount) {
      for (let i = 0; i < parseInt(this.$refs.addressCount.value); i++) {
        AddressForm(this.$refs[`address${i}`], 'en')

        this.addressToggles[i] = false
      }
    }
  },

  toggleNewAddressForm () {
    this.isNewAddressFormShown = !this.isNewAddressFormShown
  },
  toggleAddressForm (index: number) {
    this.addressToggles[index] = !this.addressToggles[index]
  },
  deleteAddress (event: MouseEvent) {
    const confirmMessage = (event.target as HTMLElement).getAttribute('data-confirm-message')
    if (!window.confirm(confirmMessage || 'Are you sure you wish to delete this address?')) {
      event.preventDefault()
    }
  }
})

export default CustomerAddresses
