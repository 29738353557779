const FacetsFilterMetaobjectFieldset = () => ({
  isCollapsed: false,
  originalHeight: 0,

  async init () {
    await this.$nextTick()

    this.originalHeight = this.$el.scrollHeight

    this.$watch('isCollapsed', () => {
      this.originalHeight = this.$el.scrollHeight
    })
  }
})

export default FacetsFilterMetaobjectFieldset
