import axios from 'axios'
import { formatMoney } from '../utils/helpers'

const cartAxiosInstace = axios.create({
  baseURL: '/',
  headers: {
    'X-Requested-With': 'XMLHttpRequest'
  }
})

const CartStore = {
  isMiniCartOpen: false,

  isFetchingCart: true,
  isAddingToCart: false,
  isChangingCart: false,
  isUpdatingCart: false,

  cart: null,

  promotionFreeShipping: null,
  promotionRemaining: null,
  promotionRemainingPercent: null,
  promotionText: null,

  userCountry: null,

  init () {
    this.fetchCart({})
  },

  toggleMiniCart (state) {
    console.log(state)
    this.isMiniCartOpen = false

    if (state === undefined) {
      this.isMiniCartOpen = !this.isMiniCartOpen

      return
    }

    this.isMiniCartOpen = state
  },

  async fetchCart ({ showLoading = true }) {
    if (showLoading) {
      this.isFetchingCart = true
    }

    try {
      const response = await axios.get('/cart?view=data')

      this.cart = response.data
    } catch (error) {
      console.error(error, 'Error in fetching cart')
    } finally {
      this.isFetchingCart = false
    }
  },

  async addToCart ({
    variantId,
    quantity = 1,
    properties = {},
    shouldFetchCart = true,
    showFetchCartLoading = true,
    showMiniCartOnSuccess = true,
    ...params
  }) {
    if (!variantId) return

    this.isAddingToCart = !this.isAddingToCart

    const data = {
      quantity,
      id: variantId,
      ...params,
      ...(properties && {
        properties
      })
    }

    return await cartAxiosInstace
      .post('/cart/add.js', data)
      .then((res) => {
        if (showMiniCartOnSuccess) {
          this.toggleMiniCart(true)
        }

        if (shouldFetchCart) {
          this.fetchCart({ showLoading: showFetchCartLoading })
        }

        return { success: true, res }
      })
      .catch((error) => {
        console.error(error, 'error')

        if (error.response.data.description && error.response.status === 422) {
          alert(error.response.data.description)
        }

        return { success: false }
      })
      .finally(() => {
        this.isAddingToCart = false
      })
  },

  /**
   * Used for updating the quantity
   *
   * @param {string, number, boolean} { key, quantity, shouldFetchCart = true }
   * @return {*}
   */
  async updateCart ({
    key,
    quantity,
    shouldFetchCart = true,
    showFetchCartLoading = true
  }) {
    if (!key) return

    this.isUpdatingCart = true

    const updates = {
      [key]: quantity
    }

    return await cartAxiosInstace
      .post('/cart/update.js', { updates })
      .then(async (res) => {
        if (shouldFetchCart) {
          await this.fetchCart({ showLoading: showFetchCartLoading })
        }

        return { success: true }
      })
      .catch((error) => {
        if (error.response.data.description && error.response.status === 422) {
          alert(error.response.data.description)
        }

        return { success: false }
      })
      .finally(() => {
        this.isUpdatingCart = false
      })
  },

  /**
   * Used for updating the line item properties
   *
   * @param {string, number, object, boolean} { line, quantity, properties, shouldFetchCart = true }
   * @return {*}
   */
  async changeCart ({
    line,
    quantity,
    properties,
    shouldFetchCart = true,
    showFetchCartLoading = true
  }) {
    if (!line) return

    this.isChangingCart = true

    return await cartAxiosInstace
      .post('/cart/change.js', {
        line,
        quantity,
        properties
      })
      .then(async () => {
        if (shouldFetchCart) {
          await this.fetchCart({ showLoading: showFetchCartLoading })
        }

        return { success: true }
      })
      .catch((error) => {
        console.log('Error in changing cart')
        throw new Error(error.message)
      })
      .finally(() => {
        this.isChangingCart = false
      })
  }
}

export default CartStore
