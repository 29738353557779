const Collapsible = () => ({
  openedIndex: 0,

  toggleCollapsible (index: number) {
    if (this.openedIndex !== index) {
      this.openedIndex = index
      return
    }

    this.openedIndex = null
  }
})

export default Collapsible
