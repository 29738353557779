/**
 * Convert an object into URL parameter format.
 *
 * @param {Object} obj - The object to convert.
 * @returns {string} The URL parameter representation of the object.
 *
 * @example
 * const urlParamsObj = {
 *     section_id: 'someId',
 *     sort_by: 'someSort',
 *     sampleArray: ['val1','val2']
 * };
 * console.log(toUrlParams(urlParamsObj));
 * // Output: section_id=someId&sort_by=someSort&sampleArray=val1&sampleArray=val2
 */
export function toUrlParams (obj) {
  const params = []

  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      // If the property value is an array, create multiple parameters
      if (Array.isArray(obj[key])) {
        obj[key].forEach((value) => {
          params.push(`${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
        })
      } else {
        // For non-array values, just add the key-value pair as a parameter
        params.push(
          `${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`
        )
      }
    }
  }

  return params.join('&')
}

/**
 * Converts an object into a filtered URL parameter format.
 * Filters out "section_id" key.
 *
 * @param {Object} params - The object containing parameters to convert.
 * @returns {string} The filtered URL parameter representation of the object.
 *
 * @example
 * const paramsObj = {
 *     section_id: '12345',
 *     sort_by: 'date',
 *     tags: ['red', 'large']
 * };
 * console.log(toFilteredUrlParams(paramsObj));
 * // Output: sort_by=date&tags=red&tags=large
 */
export function toFilteredUrlParams (params) {
  const url = new URLSearchParams()

  for (const key in params) {
    if (key !== 'section_id') {
      if (Array.isArray(params[key])) {
        // If the property is an array, add each item individually
        for (const value of params[key]) {
          url.append(key, value)
        }
      } else {
        url.append(key, params[key])
      }
    }
  }

  return url.toString()
}

/**
 * Updates a DOM element with new content from a given HTML string.
 *
 * @param {string} selector - The selector of the element to update.
 * @param {string} responseHTML - The HTML string containing the updated content.
 */
export function updateDOMContent (selector, responseHTML) {
  const parser = new DOMParser()
  const doc = parser.parseFromString(responseHTML, 'text/html')

  const newContentElement = doc.querySelector(selector)
  const newContent = newContentElement ? newContentElement.innerHTML : null

  const currentElement = document.querySelector(selector)
  if (currentElement) {
    if (newContent) {
      currentElement.innerHTML = newContent
      currentElement.style.display = ''
    } else {
      currentElement.innerHTML = ''
      currentElement.style.display = 'none'
    }
  }
}

/**
 * Updates the browser's URL without reloading the page.
 *
 * @param {string} newURL - The new URL to set.
 */
export function updateURL (newURL) {
  window.history.pushState({}, '', newURL)
}

/**
 * Scrolls the page to a specific section smoothly with an offset.
 *
 * @param {string} sectionId - The ID of the section to scroll to.
 */
export function scrollToSection (sectionId) {
  const section = document.getElementById(sectionId)
  if (section) {
    const announcementHeight = getComputedStyle(document.documentElement).getPropertyValue('--announcement-height')
    const mainHeaderHeight = getComputedStyle(document.documentElement).getPropertyValue('--main-header-height')

    const offset = parseInt(announcementHeight) + parseInt(mainHeaderHeight)

    const sectionTop = section.getBoundingClientRect().top + window.pageYOffset

    window.scrollTo({
      top: sectionTop - offset
    })
  } else {
    console.error(`Section with ID "${sectionId}" not found.`)
  }
}

export function isEmailValid (email) {
  const pattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/

  return pattern.test(email)
}

/**
 * Format an integer to a money format with the current currency symbol.
 *
 * @param {number} amount - The integer to format as money.
 * @returns {string} - The formatted money string.
 */
export function formatMoney (amount) {
  if (
    typeof window.shopCurrencySymbol !== 'string' ||
    typeof amount !== 'number'
  ) {
    throw new Error(
      'Invalid input. Amount must be a number, and window.shopCurrencySymbol must be defined and a string.'
    )
  }

  const formattedAmount = (amount / 100).toFixed(2) // Assuming the integer represents cents
  return window.shopCurrencySymbol + formattedAmount
}

/**
 * Get offset of element relative to document.
 *
 * @param {element} el
 * @return {Object} The top and left offset of the element relative to the document
 */
export function getElementOffset (el) {
  let top = 0
  let left = 0
  let element = el

  // Loop through the DOM tree
  // and add it's parent's offset to get page offset
  do {
    top += element.offsetTop || 0
    left += element.offsetLeft || 0
    element = element.offsetParent
  } while (element)

  return {
    top,
    left
  }
}

/**
 * Retrieve tag/s from an array with the specified prefix
 *
 * @param {Array, String, Boolean} { tags, prefix, multiple }
 * @returns Array
 */
export const getTagsFromPrefix = ({ tags, prefix, multiple = false }) => {
  if (!tags) return null

  if (!multiple) {
    const tagWithPrefix = tags.find(tag => tag.includes(prefix))

    return tagWithPrefix ? tagWithPrefix.split(prefix)[1] : null
  }

  const tagsWithPrefix = tags.filter(tag => tag.includes(prefix))

  return tagsWithPrefix.map(tag => tag.split(prefix)[1])
}

/**
 * Retrieve tag/s from an array without prefix
 *
 * @param {Array, String, Boolean} { tags, prefix, multiple }
 * @returns Array
 */
export const getTagWithoutPrefix = ({ tags, value }) => {
  if (!tags) return null

  return tags.find(tag => tag === value)
}

/**
 * Mutate Storefront's response data
 * Add as much as properties as needed
 *
 * @param {Object} node
 */
export const transformStorefrontData = node => ({
  available: node.availableForSale,
  id: Number(node.id.replace('gid://shopify/Product/', '')),
  handle: node.handle,
  ...node.images.edges?.length && {
    images: node.images.edges.map(({ node }) => ({
      alt: node.altText || node.title,
      height: node.height,
      src: node.originalSrc,
      width: node.width
    }))
  },
  compare_at_price_max: Number(node.compareAtPriceRange.maxVariantPrice.amount * 100),
  compare_at_price_min: Number(node.compareAtPriceRange.minVariantPrice.amount * 100),
  ...node.metafields && node.metafields[0] !== null && {
    metafields: node.metafields
  },
  ...node.options && {
    options: node.options.map(option => option.name),
    options_with_values: node.options.map(option => {
      return {
        name: option.name,
        position: node.options.indexOf(option) + 1,
        values: option.values
      }
    })
  },
  price_max: Number(node.priceRange.maxVariantPrice.amount * 100),
  price_min: Number(node.priceRange.minVariantPrice.amount * 100),
  tags: node.tags,
  title: node.title,
  ...node.totalInventory && {
    totalInventory: node.totalInventory
  },
  type: node.productType,
  ...node.variants && {
    variants: node.variants.edges.map(({ node }) => {
      return {
        available: node.availableForSale,
        id: Number(node.id.replace('gid://shopify/ProductVariant/', '')),
        ...node.selectedOptions && {
          options: node.selectedOptions.map(option => option.value),
          option1: node.selectedOptions[0].value,
          option2: node.selectedOptions[1]?.value || null,
          option3: node.selectedOptions[1]?.value || null,
          selectedOptions: node.selectedOptions
        },
        ...node.priceV2 && { price: Number(node.priceV2.amount * 100) || null },
        ...node.selectedOptions && {
        }
      }
    })
  }
})

/**
 * Sorts an array of size strings from smallest to largest, with "OS" always sorted last.
 * @param {string[]} sizes - The array of size strings to sort.
 * @return {string[]} - The sorted array of size strings.
 */
export const sortLetteredSizes = (sizes) => {
  /**
   * Helper function to assign a numeric value to a size string based on its position in the size scale.
   * @param {string} size - The size string to evaluate.
   * @return {number} - The numeric value representing the size string's position in the size scale.
   */
  function sizeValue (size) {
    if (size === 'OS') return Infinity // OS always goes last

    const baseSizes = { S: 0, M: 1, L: 2 }
    const xCount = (size.match(/X/g) || []).length

    if (size.endsWith('S')) {
      return baseSizes.S * 10 - xCount
    } else if (size.endsWith('L')) {
      return baseSizes.L * 10 + xCount
    } else if (size === 'M') {
      return baseSizes.M * 10
    }
  }

  return sizes.slice().sort((a, b) => sizeValue(a) - sizeValue(b))
}

export const getStorefrontAccessToken = () => {
  const storefrontAccessToken = {
    au: 'f4ce0b0cc27f8cdd80f6df5a63ff4c74',
    us: 'bcf2d7d2feb9ee36ecf55b7510a00ef7'
  }

  switch (window.Shopify.shop) {
    case 'panaxea-au.myshopify.com':
      return storefrontAccessToken.au
    case 'panaxea-us.myshopify.com':
      return storefrontAccessToken.us
  }
}
