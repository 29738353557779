const FacetsProductCard = () => ({
  quantity: 1,

  async handleFormSubmit () {
    if (!window.cartAddUrl) return

    try {
      this.isAddingToCart = true
      this.isOptionsUpdating = true

      await window.Alpine.store('cart').addToCart({
        quantity: this.quantity,
        variantId: this.currentVariant.id
      })
    } catch (error) {
      console.log(error)
    } finally {
      this.isAddingToCart = false
      this.isOptionsUpdating = false
    }
  }
})

export default FacetsProductCard
