import 'vite/modulepreload-polyfill'
import Alpine from 'alpinejs'

import CartStore from '@scripts/store/cart-store'

import CartItem from '@scripts/components/cart-item'
import Collapsible from '@scripts/components/collapsible'
import Facets from '@scripts/components/facets/facets'
import FacetsProductCard from '@scripts/components/facets/facets-product-card'
import FacetsFilterMetaobject from '@scripts/components/facets/facets-filter-metaobject'
import FacetsFilterMetaobjectFieldset from '@scripts/components/facets/facets-filter-metaobject-fieldset'
import SwiperCarousel from '@scripts/components/swiper-carousel'
import PredictiveSearch from '@scripts/components/predictive-search'
import Product from '@scripts/components/product'
import QuantityField from '@scripts/components/quantity-field'
import ReadMore from '@scripts/components/read-more'

import AnnouncementBar from '@scripts/sections/announcement-bar'
import HeaderMain from '@scripts/sections/header'
import IngredientsIndex from '@scripts/sections/ingredients-index'
import ProductGallery from '@scripts/sections/main-product/product-gallery'
import EventsGrid from '@scripts/sections/events-grid'
import ProductSearchWidget from '@scripts/sections/product-search-widget'
import MainBlog from '@scripts/sections/main-blog'
import CustomerAddresses from '@scripts/sections/customer-addresses'

window.Alpine = Alpine

document.addEventListener('alpine:init', () => {
  // store
  Alpine.store('cart', CartStore)

  // components
  Alpine.data('collapsible', Collapsible)
  Alpine.data('facets', Facets)
  Alpine.data('facetsProductCard', FacetsProductCard)
  Alpine.data('facetsFilterMetaobject', FacetsFilterMetaobject)
  Alpine.data('facetsFilterMetaobjectFieldset', FacetsFilterMetaobjectFieldset)
  Alpine.data('cartItem', CartItem)
  Alpine.data('swiperCarousel', SwiperCarousel)
  Alpine.data('predictiveSearch', PredictiveSearch)
  Alpine.data('product', Product)
  Alpine.data('productGallery', ProductGallery)
  Alpine.data('quantityField', QuantityField)
  Alpine.data('readMore', ReadMore)

  // sections
  Alpine.data('announcementBar', AnnouncementBar)
  Alpine.data('headerMain', HeaderMain)
  Alpine.data('eventsGrid', EventsGrid)
  Alpine.data('productSearchWidget', ProductSearchWidget)
  Alpine.data('mainBlog', MainBlog)
  Alpine.data('ingredientsIndex', IngredientsIndex)
  Alpine.data('customerAddresses', CustomerAddresses)
})

Alpine.start()
