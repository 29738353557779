import Swiper from 'swiper'
import { Scrollbar, Navigation, Pagination } from 'swiper/modules'

import 'swiper/css'
import 'swiper/css/scrollbar'
import 'swiper/css/navigation'
import 'swiper/css/pagination'

const SwiperCarousel = ({
  showScrollbarTablet = false,
  showNavigationTablet = false,
  showPaginationTablet = false,
  showNavigationDesktop = false,
  showPaginationDesktop = false,
  showScrollbarDesktop = false,
  showNavigationMobile = false,
  showPaginationMobile = false,
  showScrollbarMobile = true,
  slidesPerViewDesktop = 3,
  slidesPerViewMobile = 1.5,
  slidesPerViewTablet = 2.5,
  spaceBetweenDesktop = 30,
  spaceBetweenMobile = 8,
  slidesOffsetAfterDesktop = 0,
  slidesOffsetBeforeDesktop = 0,
  slidesOffsetAfterTablet = 0,
  slidesOffsetBeforeTablet = 0,
  slidesOffsetAfterMobile = 0,
  slidesOffsetBeforeMobile = 0
}) => ({
  init () {
    this.initSwiper()
  },

  swiper: null,

  initSwiper () {
    this.swiper = new Swiper(this.$refs.swiper, {
      breakpoints: {
        1024: {
          scrollbar: {
            enabled: showScrollbarDesktop
          },
          navigation: {
            enabled: showNavigationDesktop,
            nextEl: this.$refs.navNext,
            prevEl: this.$refs.navPrev
          },
          pagination: {
            enabled: showPaginationDesktop,
            el: this.$refs.swiperPagination
          },
          spaceBetween: spaceBetweenDesktop,
          slidesOffsetAfter: slidesOffsetAfterDesktop,
          slidesOffsetBefore: slidesOffsetBeforeDesktop,
          slidesPerView: slidesPerViewDesktop
        },
        768: {
          scrollbar: {
            enabled: showScrollbarTablet
          },
          navigation: {
            enabled: showNavigationTablet,
            nextEl: this.$refs.navNext,
            prevEl: this.$refs.navPrev
          },
          pagination: {
            enabled: showPaginationTablet,
            el: this.$refs.swiperPagination
          },
          slidesOffsetAfter: slidesOffsetAfterTablet,
          slidesOffsetBefore: slidesOffsetBeforeTablet,
          slidesPerView: slidesPerViewTablet
        }
      },
      scrollbar: {
        draggable: true,
        enabled: showScrollbarMobile,
        el: '.swiper-scrollbar'
      },
      navigation: {
        enabled: showNavigationMobile,
        nextEl: this.$refs.navNext,
        prevEl: this.$refs.navPrev
      },
      pagination: {
        enabled: showPaginationMobile,
        el: this.$refs.swiperPagination
      },
      modules: [Navigation, Pagination, Scrollbar],
      slidesOffsetAfter: slidesOffsetAfterMobile,
      slidesOffsetBefore: slidesOffsetBeforeMobile,
      spaceBetween: spaceBetweenMobile,
      slidesPerView: slidesPerViewMobile,
      resistanceRatio: 0
    })
  }
})

export default SwiperCarousel
