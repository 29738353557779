import Cookies from 'js-cookie'

const AnnouncementBar = () => ({
  isShown: Cookies.get('announcement-shown') ? JSON.parse(Cookies.get('announcement-shown')) : true,

  init () {
    if (this.isShown === false) {
      this.setMainPaddingTop()
    }
  },

  handleCloseClick () {
    Cookies.set('announcement-shown', false, { expires: 7 })
    this.isShown = false

    this.setMainPaddingTop()
  },

  setMainPaddingTop () {
    const mainHeaderHeight = getComputedStyle(document.documentElement).getPropertyValue('--main-header-height')

    document.getElementById('MainContent').style.paddingTop = mainHeaderHeight
  }

})

export default AnnouncementBar
