import axios from 'axios'

import { getStorefrontAccessToken } from '@scripts/utils/helpers'

export const storefrontInstance = axios.create({
  baseURL: `https://${window.Shopify.shop}/api`,
  headers: {
    'Content-Type': 'application/graphql',
    'X-Shopify-Storefront-Access-Token': getStorefrontAccessToken()
  }
})
