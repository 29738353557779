import Swiper from 'swiper'
import { Thumbs } from 'swiper/modules'

import 'swiper/css'
import 'swiper/css/thumbs'

const ProductGallery = ({ activeMedia }) => {
  return {
    activeMedia,

    init () {
      this.initGallery()

      document.addEventListener('active-media-change', (event: CustomEvent) => {
        this.activeMedia = event.detail.activeMedia

        if (!this.mainGallery) return

        this.mainGallery.slideTo(this.activeMedia - 1)
      })
    },

    mainGallery: null,
    navGallery: null,

    initGallery () {
      this.navGallery = new Swiper(this.$refs.navSwiper, {
        breakpoints: {
          1024: {
            direction: 'vertical',
            slidesPerView: 3
          }
        },
        direction: 'horizontal',
        grabCursor: true,
        resistanceRatio: 0,
        slidesPerView: 'auto',
        spaceBetween: 10,
        watchSlidesProgress: true
      })

      this.mainGallery = new Swiper(this.$refs.mainSwiper, {
        autoHeight: true,
        grabCursor: true,
        modules: [Thumbs],
        resistanceRatio: 0,
        spaceBetween: 10,
        thumbs: {
          swiper: this.navGallery
        }
      })
    }
  }
}

export default ProductGallery
