import axios from 'axios'
import {
  toUrlParams,
  updateDOMContent,
  updateURL,
  toFilteredUrlParams
} from '@scripts/utils/helpers'

const ELEMENTS_TO_UPDATE_CONTENT = [
  '[data-product-fieldsets]',
  '[data-submit-wrapper]'
]

const Product = ({
  firstAvailableVariant,
  product,
  sectionId
}) => ({
  activeMedia: 0,

  currentVariant: null,
  selectedOptions: [],
  isOptionsUpdating: false,
  quantity: 1,

  isAddingToCart: false,

  init () {
    this.$watch('currentVariant', (value) => {
      this.activeMedia = value.featured_media?.position || this.activeMedia
      if (this.hasProductInit) {
        document.dispatchEvent(
          new CustomEvent('active-media-change', {
            detail: { activeMedia: this.activeMedia }
          })
        )
      }
    })

    this.setCurrentVariant()
    this.setSelectedOptions()
  },

  getRequestParams () {
    return {
      section_id: sectionId,
      variant: this.currentVariant.id
    }
  },

  setCurrentVariant (variantId) {
    if (variantId) {
      const variant = product.variants.find(
        (variant) => variant.id === parseInt(urlVariant)
      )
      this.currentVariant = variant || product.variants[0]

      return
    }

    const searchParams = new URLSearchParams(window.location.search)
    const urlVariant = searchParams.get('variant')

    if (urlVariant) {
      const variant = product.variants.find(
        (variant) => variant.id === parseInt(urlVariant)
      )
      this.currentVariant = variant || product.variants[0]
    } else {
      this.currentVariant = firstAvailableVariant || product.variants[0]
    }
  },

  setSelectedOptions () {
    if (!this.currentVariant) return

    this.selectedOptions = [
      this.currentVariant.option1,
      this.currentVariant.option2,
      this.currentVariant.option3
    ].filter(Boolean)
  },

  handleInputChange () {
    const checkedOptions = Array.from(
      this.$refs.productForm.querySelectorAll('[data-option]')
    ).filter((input: HTMLInputElement) => input.checked)

    this.selectedOptions = checkedOptions.map((input: HTMLInputElement) => input.value)

    this.findVariant()
  },

  handleQuantityChange ($event) {
    this.quantity = $event.detail.value
  },

  findVariant () {
    let matchingVariant = product.variants.find((variant) => {
      return (
        variant.option1 === this.selectedOptions[0] &&
        (!this.selectedOptions[1] ||
          variant.option2 === this.selectedOptions[1]) &&
        (!this.selectedOptions[2] ||
          variant.option3 === this.selectedOptions[2])
      )
    })

    if (!matchingVariant) {
      matchingVariant = product.variants.find(
        (variant) => variant.option1 === this.selectedOptions[0]
      )
    }

    this.currentVariant = matchingVariant

    this.fetchVariant()
  },

  async fetchVariant (setOptions = true) {
    const baseEndpoint = window.location.pathname
    const serializedParams = toUrlParams(this.getRequestParams())
    const fullUrl = `${baseEndpoint}?${serializedParams}`

    try {
      this.isOptionsUpdating = true

      const response = await axios.get(fullUrl)

      ELEMENTS_TO_UPDATE_CONTENT.forEach((el) => {
        updateDOMContent(el, response.data)
      })

      updateURL(
        `${baseEndpoint}?${toFilteredUrlParams(this.getRequestParams())}`
      )

      if (setOptions) {
        this.setSelectedOptions()
      }

      this.activeMedia =
        this.currentVariant.featured_media?.position || this.activeMedia

      document.dispatchEvent(
        new CustomEvent('active-media-change', {
          detail: { activeMedia: this.activeMedia }
        })
      )
    } catch (error) {
      throw new Error('Error in updating options')
    } finally {
      this.isOptionsUpdating = false
    }
  },

  async handleFormSubmit () {
    if (!window.cartAddUrl) return

    try {
      this.isAddingToCart = true
      this.isOptionsUpdating = true

      await window.Alpine.store('cart').addToCart({
        quantity: this.quantity,
        variantId: this.currentVariant.id
      })
    } catch (error) {
      console.log(error)
    } finally {
      this.isAddingToCart = false
      this.isOptionsUpdating = false
    }
  }

})

export default Product
